import React, { memo } from 'react';
import { useAdjustmentsEnabled } from '@atlassian/jira-issue-adjustments/src/controllers/adjustments-context/index.tsx';
import { isFieldTypeSupported } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/index.tsx';
import { GIC_VIEW_TYPE } from '../../common/constants';
import { EnabledFieldValueDecorator } from './enable-field-value-decorator';
import type { EnabledFieldValueDecoratorProps } from './enable-field-value-decorator/types';

export type FieldValueDecoratorProps = EnabledFieldValueDecoratorProps<unknown, string | undefined>;

const FieldValueDecoratorAsyncNoMemo = ({
	children,
	fieldType,
	fieldProps,
	fieldId,
}: FieldValueDecoratorProps) => {
	const adjustmentsAreEnabled = useAdjustmentsEnabled();

	const undecoratedResult = children(fieldProps);

	if (isFieldTypeSupported(GIC_VIEW_TYPE, fieldType) && adjustmentsAreEnabled) {
		return (
			<EnabledFieldValueDecorator fieldProps={fieldProps} fieldId={fieldId} fieldType={fieldType}>
				{children}
			</EnabledFieldValueDecorator>
		);
	}

	return undecoratedResult;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const FieldValueDecoratorAsync = memo(
	FieldValueDecoratorAsyncNoMemo,
) as typeof FieldValueDecoratorAsyncNoMemo;
