import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedFetch: {
		id: 'issue-field-select-base.failed-fetch-options.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	reloadIssue: {
		id: 'issue-field-select-base.failed-fetch-options.reload-issue',
		defaultMessage: 'Try reloading the issue.',
		description: 'Message to prompt the user to reload the issue',
	},
	reloadIssueIssueTermRefresh: {
		id: 'issue-field-select-base.failed-fetch-options.reload-issue-issue-term-refresh',
		defaultMessage: 'Try reloading the issue.',
		description: 'Message to prompt the user to reload the issue',
	},
});
