import {
	AFFECTS_VERSIONS_TYPE,
	ASSIGNEE_TYPE,
	ATTACHMENT_TYPE,
	CASCADING_SELECT_CF_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	DATETIME_CF_TYPE,
	DATE_CF_TYPE,
	DESCRIPTION_TYPE,
	DUE_DATE_TYPE,
	FIX_VERSIONS_TYPE,
	GROUP_CF_TYPE,
	LABELS_CF_TYPE,
	LABELS_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	NUMBER_CF_TYPE,
	PARENT_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	SELECT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	SPRINT_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SUMMARY_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	TEAM_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	URL_CF_TYPE,
	USER_CF_TYPE,
	VERSION_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import { isWorkIntakeRollout1EnabledNoExp } from '../work-intake-rollout-1-experiment';
import { AffectedServicesCustomFieldTypeConfig } from './field-types/affected-services-custom-field-type';
import { AssigneeFieldTypeConfig } from './field-types/assignee-field-type';
import { AttachmentFieldTypeConfig } from './field-types/attachment-field-type';
import { CascadingSelectFieldTypeConfig } from './field-types/cascading-select-custom-field-type';
import { CategoryFieldTypeConfig } from './field-types/category-field-type';
import { ComponentsFieldTypeConfig } from './field-types/components-field-type';
import { DateCustomFieldTypeConfig } from './field-types/date-custom-field-type';
import { DateTimeCustomFieldTypeConfig } from './field-types/date-time-custom-field-type';
import { DescriptionFieldTypeConfig } from './field-types/description-field-type';
import { DueDateFieldTypeConfig } from './field-types/due-date-field-type';
import { GroupCustomFieldTypeConfig } from './field-types/group-custom-field-type';
import { LabelsCustomFieldTypeConfig } from './field-types/labels-custom-field-type';
import { LabelsFieldTypeConfig } from './field-types/labels-field-type';
import { MultiCheckboxesCustomFieldTypeConfig } from './field-types/multi-checkboxes-custom-field-type';
import { MultiGroupCustomFieldTypeConfig } from './field-types/multi-group-custom-field-type';
import { MultiSelectCustomFieldTypeConfig } from './field-types/multi-select-custom-field-type';
import { MultiUserCustomFieldTypeConfig } from './field-types/multi-user-custom-field-type';
import { NumberCustomFieldTypeConfig } from './field-types/number-custom-field-type';
import { ParentFieldTypeConfig } from './field-types/parent-field-type';
import { PeopleCustomFieldType } from './field-types/people-custom-field-type';
import { PriorityFieldTypeConfig } from './field-types/priority-field-type';
import { RadioButtonsCustomFieldTypeConfig } from './field-types/radio-buttons-custom-field-type';
import { SelectCustomFieldTypeConfig } from './field-types/select-custom-field-type';
import { SprintFieldTypeConfig } from './field-types/sprint-field-type';
import { StoryPointEstimateCustomFieldTypeConfig } from './field-types/story-point-estimate-custom-field-type';
import { SummaryFieldTypeConfig } from './field-types/summary-field-type';
import { TeamCustomFieldTypeConfig } from './field-types/team-custom-field-type';
import { TeamsPlatformCustomFieldTypeConfig } from './field-types/teams-platform-custom-field-type';
import { TextAreaCustomFieldTypeConfig } from './field-types/text-area-custom-field-type';
import { TextCustomFieldTypeConfig } from './field-types/text-custom-field-type';
import { UrlCustomFieldTypeConfig } from './field-types/url-custom-field-type';
import { UserCustomFieldTypeConfig } from './field-types/user-custom-field-type';
import {
	VersionFieldTypeConfig,
	MultiVersionsFieldTypeConfig,
} from './field-types/versions-field-type';
import type {
	FieldTypeConfig,
	SoftwareSupportedFieldType,
	SupportedFieldType,
	BaseSupportedFieldType,
	ProjectScopedSupportedFieldType,
} from './types';

const EXTENDED_CONFIGS_TYPES: string[] = [
	PEOPLE_CF_TYPE,
	CATEGORY_TYPE,
	PARENT_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	COMPONENTS_TYPE,
	SPRINT_TYPE,
	AFFECTS_VERSIONS_TYPE,
	VERSION_CF_TYPE,
	FIX_VERSIONS_TYPE,
	MULTI_VERSION_CF_TYPE,
];

const PROJECT_SCOPED_FIELD_TYPE_CONFIGS: Record<
	ProjectScopedSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	[PARENT_TYPE]: ParentFieldTypeConfig,
	[SPRINT_TYPE]: SprintFieldTypeConfig,
};

const BASE_FIELD_TYPE_CONFIGS: Record<
	BaseSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	[AFFECTS_VERSIONS_TYPE]: MultiVersionsFieldTypeConfig(AFFECTS_VERSIONS_TYPE),
	[ASSIGNEE_TYPE]: AssigneeFieldTypeConfig,
	[ATTACHMENT_TYPE]: AttachmentFieldTypeConfig,
	[CASCADING_SELECT_CF_TYPE]: CascadingSelectFieldTypeConfig,
	[CATEGORY_TYPE]: CategoryFieldTypeConfig,
	[COMPONENTS_TYPE]: ComponentsFieldTypeConfig,
	[DATETIME_CF_TYPE]: DateTimeCustomFieldTypeConfig,
	[DATE_CF_TYPE]: DateCustomFieldTypeConfig,
	[DESCRIPTION_TYPE]: DescriptionFieldTypeConfig,
	[DUE_DATE_TYPE]: DueDateFieldTypeConfig,
	[FIX_VERSIONS_TYPE]: MultiVersionsFieldTypeConfig(FIX_VERSIONS_TYPE),
	[GROUP_CF_TYPE]: GroupCustomFieldTypeConfig,
	[LABELS_CF_TYPE]: LabelsCustomFieldTypeConfig,
	[LABELS_TYPE]: LabelsFieldTypeConfig,
	[MULTI_CHECKBOXES_CF_TYPE]: MultiCheckboxesCustomFieldTypeConfig,
	[MULTI_GROUP_CF_TYPE]: MultiGroupCustomFieldTypeConfig,
	[MULTI_SELECT_CF_TYPE]: MultiSelectCustomFieldTypeConfig,
	[MULTI_USER_CF_TYPE]: MultiUserCustomFieldTypeConfig,
	[MULTI_VERSION_CF_TYPE]: MultiVersionsFieldTypeConfig(MULTI_VERSION_CF_TYPE),
	[NUMBER_CF_TYPE]: NumberCustomFieldTypeConfig,
	[PARENT_TYPE]: ParentFieldTypeConfig,
	[PEOPLE_CF_TYPE]: PeopleCustomFieldType,
	[PRIORITY_TYPE]: PriorityFieldTypeConfig,
	[RADIO_BUTTONS_CF_TYPE]: RadioButtonsCustomFieldTypeConfig,
	[SELECT_CF_TYPE]: SelectCustomFieldTypeConfig,
	[SERVICE_ENTITY_CF_TYPE]: AffectedServicesCustomFieldTypeConfig,
	[STORY_POINT_ESTIMATE_CF_TYPE]: StoryPointEstimateCustomFieldTypeConfig,
	[SUMMARY_TYPE]: SummaryFieldTypeConfig,
	[TEAMS_PLATFORM_CF_TYPE]: TeamsPlatformCustomFieldTypeConfig,
	[TEAM_CF_TYPE]: TeamCustomFieldTypeConfig,
	[TEXT_AREA_CF_TYPE]: TextAreaCustomFieldTypeConfig,
	[TEXT_CF_TYPE]: TextCustomFieldTypeConfig,
	[URL_CF_TYPE]: UrlCustomFieldTypeConfig,
	[USER_CF_TYPE]: UserCustomFieldTypeConfig,
	[VERSION_CF_TYPE]: VersionFieldTypeConfig,
};

const SOFTWARE_FIELD_TYPE_CONFIGS: Record<
	SoftwareSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	[SPRINT_TYPE]: SprintFieldTypeConfig,
};

const FIELD_TYPE_CONFIGS: Record<
	BaseSupportedFieldType | SoftwareSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	...BASE_FIELD_TYPE_CONFIGS,
	...SOFTWARE_FIELD_TYPE_CONFIGS,
};

export function isFieldTypeSupported(
	type: string,
	// Make this parameter required when cleaning up `forms_for_work_intake_rollout_1_experiment`
	projectType?: string,
): type is SupportedFieldType {
	if (!isWorkIntakeRollout1EnabledNoExp()) {
		return type in FIELD_TYPE_CONFIGS && !EXTENDED_CONFIGS_TYPES.includes(type);
	}

	if (projectType === 'software') {
		return type in BASE_FIELD_TYPE_CONFIGS || type in SOFTWARE_FIELD_TYPE_CONFIGS;
	}
	return type in BASE_FIELD_TYPE_CONFIGS;
}

// Make `projectType` parameter required when cleaning up `forms_for_work_intake_rollout_1_experiment`
export function getFieldTypeConfig(fieldType: string, projectType?: string) {
	return isFieldTypeSupported(fieldType, projectType) ? FIELD_TYPE_CONFIGS[fieldType] : undefined;
}

// Delete FORM_LABEL on jwm_forms_to_jsw FG cleanup
export const FORM_LABEL = 'business-form';

export const getFormLabelPrefix = (projectType: string | undefined) => {
	if (projectType != null && projectType === 'software') {
		return 'software-form';
	}
	if (projectType != null && projectType === 'business') {
		return 'business-form';
	}
	return 'form';
};

export const getUniqueFormLabel = (formLabelPrefix: string, formId: string) =>
	`${formLabelPrefix}-${formId}`;

export const isProjectScopedFieldType = (type: string) => {
	if (!isWorkIntakeRollout1EnabledNoExp()) {
		return false;
	}

	return type in PROJECT_SCOPED_FIELD_TYPE_CONFIGS;
};
