import React from 'react';
import SelectField, {
	type SelectFieldProps,
} from '../../common/ui/fields/base-fields/select-field';

const MultiSelectField = (props: SelectFieldProps) => <SelectField {...props} isMulti />;

export default MultiSelectField;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateMultiSelectField, mapMultiSelectOptionsToConnectData } from './utils';
