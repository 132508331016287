import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import AkHeading from '@atlaskit/heading';
import { JiraIcon } from '@atlaskit/logo';
import { Text } from '@atlaskit/primitives';
import { N200, N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import LoadingFileErrorComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/loading-file-error';
import LockRedComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/lock-red';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/import/no-restricted-import
import { useProject } from '@atlassian/jira-router-resources-business-project-details';
import { messages } from './messages';

export type SuccessViewProps = {
	shouldHidePoweredBy: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onResetClick?: any;
	analyticAttributes?: {
		accessLevel?: string;
		isOutsideUser?: boolean;
	};
};
const contactAdminUrl = '/jira/contact-administrator';

const ContactYourAdminLinkMessage = (chunks: ReactNode) => (
	<Link href={contactAdminUrl}>{chunks}</Link>
);

const WAC_LINK =
	'https://www.atlassian.com/software/jira?utm_source=in-product-banner&utm_medium=inapp&utm_campaign=P:jira-work-management%7CO:cloudpmm-jwm%7CF:discovery%7CE:cloud%7CX:expand%7C';

export const SuccessView = (props: SuccessViewProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { shouldHidePoweredBy, onResetClick, analyticAttributes } = props;

	const onSubmitAnotherResponse = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'submitAnotherResponseForForm', {
			...analyticAttributes,
		});
		onResetClick();
	};

	return (
		<ContextualAnalyticsData sourceName="businessFormSuccessView" sourceType={SCREEN}>
			<FireScreenAnalytics />
			<SuccessWrapper shouldHidePoweredBy={shouldHidePoweredBy}>
				<Heading>{formatMessage(messages.successSubmissionHeading)}</Heading>
				<Text as="p">{formatMessage(messages.successSubmissionContentExperiment)}</Text>

				<SubmitAnotherButtonWrapper>
					<LoadingButton appearance="primary" onClick={onSubmitAnotherResponse}>
						{formatMessage(messages.formSubmitAnotherForm)}
					</LoadingButton>
				</SubmitAnotherButtonWrapper>

				{!shouldHidePoweredBy && (
					<PromoFooter>
						<FormattedI18nMessage
							message={formatMessage(messages.formSuccessFooterSpork, {
								linkStart: '{linkStart}',
								linkEnd: '{linkEnd}',
							})}
							componentsMapping={{
								link: ({ children }) => (
									<>
										<JiraIcon size="small" appearance="brand" />
										<a
											href={WAC_LINK}
											target="_blank"
											onClick={() => {
												fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'poweredByJwm');
											}}
										>
											{children}
										</a>
									</>
								),
							}}
						/>
					</PromoFooter>
				)}
			</SuccessWrapper>
		</ContextualAnalyticsData>
	);
};

export const NoPermissionView = () => {
	const { formatMessage } = useIntl();

	return (
		<ContextualAnalyticsData sourceName="businessFormNoPermissionView" sourceType={SCREEN}>
			<FireScreenAnalytics />
			<EmptyState
				header={formatMessage(messages.noPermissionHeading)}
				headingLevel={1}
				description={
					<FormattedMessage
						{...messages.noPermissionContent}
						values={{
							createPermissionName: (
								<Text as="strong">{formatMessage(messages.createPermissionName)}</Text>
							),
							linkToContactAdmin: (
								<Link
									// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
									onClick={(event, analyticsEvent: UIAnalyticsEvent) => {
										fireUIAnalytics(analyticsEvent, 'button clicked', 'contactAdministrator');
									}}
									href="/secure/ContactAdministrators!default.jspa"
								>
									{formatMessage(messages.noPermissionLinkToContactAdmin)}
								</Link>
							),
						}}
					/>
				}
				maxImageWidth={100} // need to inforce width as the image is too big
				renderImage={getAkEmptyStateRenderImageFn(LockRedComponent)}
			/>
		</ContextualAnalyticsData>
	);
};

export const FormUnavailableView = () => {
	const { formatMessage } = useIntl();
	return (
		<ContextualAnalyticsData sourceName="businessFormUnavailableView" sourceType={SCREEN}>
			<FireScreenAnalytics />
			<EmptyState
				header={formatMessage(messages.formUnavailableHeading)}
				headingLevel={1}
				description={formatMessage(messages.formUnavailableContent)}
				renderImage={getAkEmptyStateRenderImageFn(LoadingFileErrorComponent)}
			/>
		</ContextualAnalyticsData>
	);
};

export const IssueTypeNotAssociatedView = ({ issueTypeName }: { issueTypeName: string }) => {
	const { formatMessage } = useIntl();
	const project = useProject().data;

	const isAdmin = project && project.permissions.administerProject;

	return (
		<ContextualAnalyticsData
			sourceName="businessFormUnavailableIssueTypeRemovedView"
			sourceType={SCREEN}
		>
			<FireScreenAnalytics />
			<EmptyState
				header={formatMessage(messages.formUnavailableHeading)}
				headingLevel={1}
				description={
					isAdmin ? (
						formatMessage(messages.formUnavailableIssueTypeNotAssociatedContentForAdmins, {
							issueTypeName,
						})
					) : (
						<FormattedMessage
							{...messages.formUnavailableIssueTypeNotAssociatedContent}
							values={{
								link: ContactYourAdminLinkMessage,
							}}
						/>
					)
				}
				renderImage={getAkEmptyStateRenderImageFn(LoadingFileErrorComponent)}
			/>
		</ContextualAnalyticsData>
	);
};

export const IssueTypeDeletedView = () => {
	const { formatMessage } = useIntl();
	const project = useProject().data;
	const isAdmin = project && project.permissions.administerProject;

	return (
		<ContextualAnalyticsData
			sourceName="businessFormUnavailableIssueTypeDeletedView"
			sourceType={SCREEN}
		>
			<FireScreenAnalytics />
			<EmptyState
				header={formatMessage(messages.formUnavailableHeading)}
				headingLevel={1}
				description={
					isAdmin ? (
						formatMessage(messages.formUnavailableIssueTypeDeletedContentForAdmins)
					) : (
						<FormattedMessage
							{...messages.formUnavailableIssueTypeDeletedContent}
							values={{
								link: ContactYourAdminLinkMessage,
							}}
						/>
					)
				}
				renderImage={getAkEmptyStateRenderImageFn(LoadingFileErrorComponent)}
			/>
		</ContextualAnalyticsData>
	);
};

export const FormContainsUnsupportedRequiredFieldsView = () => {
	const { formatMessage } = useIntl();
	const project = useProject().data;
	const canCreateOrModifyForm = project && project.permissions.administerProject;
	const projectKey = project?.key;
	const isTeamManaged = project?.isSimplified;
	const fieldLayoutSchemesUrlCMP = '/secure/admin/ViewFieldLayoutSchemes.jspa';
	const fieldLayoutSchemesUrlTMP = `/jira/core/projects/${projectKey}/settings/issuetypes`;
	const fieldLayoutSchemesUrl = isTeamManaged ? fieldLayoutSchemesUrlTMP : fieldLayoutSchemesUrlCMP;
	return (
		<ContextualAnalyticsData
			sourceName="businessFormContainsUnsupportedRequiredFieldsView"
			sourceType={SCREEN}
		>
			<FireScreenAnalytics />
			<EmptyState
				header={formatMessage(messages.unsupportedRequiredFieldsHeading)}
				headingLevel={1}
				description={
					<FormattedMessage
						{...messages.unsupportedRequiredFieldsDescription}
						values={{
							canCreateOrModifyForm,
							link: (chunks: ReactNode) => (
								<Link
									onClick={(event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
										fireUIAnalytics(
											analyticsEvent,
											'button clicked',
											'formMissingUnsupportedRequiredFieldsCta',
											{
												isAdmin: canCreateOrModifyForm,
											},
										);
									}}
									href={canCreateOrModifyForm ? fieldLayoutSchemesUrl : contactAdminUrl}
								>
									{chunks}
								</Link>
							),
						}}
					/>
				}
				renderImage={getAkEmptyStateRenderImageFn(LoadingFileErrorComponent)}
			/>
		</ContextualAnalyticsData>
	);
};

export const FormMissingSupportedRequiredFieldsView = () => {
	const { formatMessage } = useIntl();
	const project = useProject().data;
	const canCreateOrModifyForm = project && project.permissions.administerProject;
	const adminUrl = typeof window !== 'undefined' ? `${window.location.href}/builder` : '';

	return (
		<ContextualAnalyticsData
			sourceName="businessFormMissingSupportedRequiredFieldsView"
			sourceType={SCREEN}
		>
			<FireScreenAnalytics />
			<EmptyState
				header={formatMessage(messages.supportedRequiredFieldsMissingHeading)}
				headingLevel={1}
				description={
					<FormattedMessage
						{...messages.supportedRequiredFieldsDescription}
						values={{
							canCreateOrModifyForm,
							link: (chunks: ReactNode) => (
								<Link
									onClick={(event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
										fireUIAnalytics(
											analyticsEvent,
											'button clicked',
											'formMissingSupportedRequiredFieldsCta',
											{
												isAdmin: canCreateOrModifyForm,
											},
										);
									}}
									href={canCreateOrModifyForm ? adminUrl : contactAdminUrl}
								>
									{chunks}
								</Link>
							),
						}}
					/>
				}
				renderImage={getAkEmptyStateRenderImageFn(LoadingFileErrorComponent)}
			/>
		</ContextualAnalyticsData>
	);
};

const illustrationHeight = 4;

const Heading = ({ children }: { children: ReactNode }) => (
	<AkHeading size="large" as="h1">
		{children}
	</AkHeading>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WrapperStyleBase = styled.div({
	textAlign: 'center',
	'&::before': {
		lineHeight: `${illustrationHeight}rem`,
		margin: `${token('space.200', '16px')} 0`,
		display: 'block',
		fontSize: `${illustrationHeight}rem`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SuccessWrapper = styled(WrapperStyleBase)<{ shouldHidePoweredBy: boolean }>({
	margin: `0 ${token('space.negative.200', '-16px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ shouldHidePoweredBy }) => (shouldHidePoweredBy ? '18px' : '0px'),
	'&::before': {
		content: '🙌',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PromoFooter = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderTop: `2px solid ${token('color.border', N30A)}`,
	marginTop: token('space.400', '32px'),
	marginBottom: token('space.200', '16px'),
	paddingTop: token('space.300', '24px'),
	color: token('color.text.subtle', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubmitAnotherButtonWrapper = styled.div({
	paddingTop: token('space.200', '16px'),
	margin: `${token('space.100', '8px')} ${token('space.400', '32px')} 0`,
});
