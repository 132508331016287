import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field';
import { SprintField } from '@atlassian/jira-issue-create-commons/src/ui/sprint-field';
import type {
	SprintFieldConfig,
	FieldValue,
} from '@atlassian/jira-issue-create-commons/src/ui/sprint-field/types';
import { transformDefaultSprintValue } from '@atlassian/jira-issue-create-commons/src/ui/sprint-field/utils';
import { validateRequired } from '../../../utils/field-validators';

export default withFormField({
	transformDefaultValue: transformDefaultSprintValue,
	validator: validateRequired,
	shouldRenderFieldMessage: false,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<SprintFieldConfig, FieldValue>, any>'.
})<SprintFieldConfig, FieldValue>(SprintField);
