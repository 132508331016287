import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field';
import AffectedServicesFieldOld, {
	AffectedServicesField as AffectedServicesFieldInternal,
} from '@atlassian/jira-issue-create-commons/src/ui/affected-services-field';
import type {
	AffectedServicesFieldConfig,
	FieldValue,
} from '@atlassian/jira-issue-create-commons/src/ui/affected-services-field/types';
import { transformDefaultAffectedServices } from '@atlassian/jira-issue-create-commons/src/ui/affected-services-field/utils';
import { validateRequired } from '../../../utils/field-validators';
import { isWorkIntakeRollout1EnabledNoExp } from '../../../utils/work-intake-rollout-1-experiment';

const AffectedServicesFieldNew = withFormField({
	transformDefaultValue: transformDefaultAffectedServices,
	validator: validateRequired,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<AffectedServicesFieldConfig, FieldValue>, any>'.
})<AffectedServicesFieldConfig, FieldValue>(AffectedServicesFieldInternal);

const AffectedServicesField = componentWithCondition(
	isWorkIntakeRollout1EnabledNoExp,
	AffectedServicesFieldNew,
	AffectedServicesFieldOld,
);

export default AffectedServicesField;
