import React from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import TextIcon from '@atlaskit/icon/core/text';
import { DESCRIPTION_TYPE } from '@atlassian/jira-platform-field-config';
import { DescriptionFieldPlaceholder } from '../../../ui/field-placeholders';
import DescriptionField from '../../../ui/fields/description-field';
import type { FieldTypeConfig, ProvidedFieldProps } from '../types';
import { descriptionFieldWriter } from '../writers';

const DescriptionFieldTypeComponent = (props: ProvidedFieldProps) => (
	<DescriptionField
		{...props}
		defaultValue={undefined}
		fieldWrapperElement={null}
		mediaUploadContext={props.mediaUploadContexts.uploadContext}
	/>
);

export const DescriptionFieldTypeConfig: FieldTypeConfig<ADF> = {
	icon: TextIcon,
	type: DESCRIPTION_TYPE,
	Component: DescriptionFieldTypeComponent,
	placeholder: <DescriptionFieldPlaceholder />,
	writer: descriptionFieldWriter,
};
