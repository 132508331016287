import React, { type ChangeEvent } from 'react';
import TextAreaEdit from '@atlassian/jira-issue-field-textarea/src/ui/edit/index.tsx';
import { FieldContainer } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import { validateString } from '../../common/utils/validators/index.tsx';
import type { TextAreaFieldConfig, FieldValue, Props } from './types';

const TextAreaField = (props: Props) => {
	const {
		width = '100%',
		fieldProps: { value = '', onChange, onBlur, ...fieldProps },
		error,
		autoFocus = false,
		isReadOnly = false,
	} = props;

	const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
		onChange(e.target.value);
	};

	return (
		<FieldContainer width={width}>
			<TextAreaEdit
				{...fieldProps}
				value={value}
				onChange={onTextAreaChange}
				onBlur={onBlur}
				isInvalid={Boolean(error)}
				autoFocus={autoFocus}
				isDisabled={isReadOnly}
			/>
		</FieldContainer>
	);
};

export default withFormField({ validator: validateString })<TextAreaFieldConfig, FieldValue>(
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<TextAreaFieldConfig, string>, any>'.
	TextAreaField,
);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateTextAreaField } from './utils';
