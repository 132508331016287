import React from 'react';
import SelectEdit from '@atlassian/jira-issue-field-select/src/ui/edit/index.tsx';
import { mapDataToSelectOptions } from '@atlassian/jira-issue-field-select/src/ui/edit/utils.tsx';
import { closeMenuOnScroll } from '../../../../utils/select/index.tsx';
import { validateSelectField } from '../../../../utils/validators';
import { withFormField } from '../../../with-form-field';
import { FieldContainer } from '../../styled';
import type { Props, SelectFieldConfig, FieldValue, SelectFieldProps } from './types';
import { transformDefaultSelectValues, onFilterOption } from './utils';

const SelectField = (props: Props) => {
	const {
		fieldId,
		autoFocus = false,
		allowedValues,
		width,
		error,
		fieldProps: { value, onChange, id, isRequired },
		isMulti = false,
		isReadOnly = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
	} = props;

	return (
		<FieldContainer width={width}>
			<SelectEdit
				onChange={onChange}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				options={mapDataToSelectOptions(allowedValues)}
				value={value}
				autoFocus={autoFocus}
				isInvalid={Boolean(error)}
				classNamePrefix={`select-${fieldId}`}
				isMulti={isMulti}
				hideSelectedOptions
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				isClearable
				onFilterOption={onFilterOption}
				isDisabled={isReadOnly}
				isRequired={isRequired}
				inputId={id}
			/>
		</FieldContainer>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SelectFieldProps };
export default withFormField({
	transformDefaultValue: transformDefaultSelectValues,
	validator: validateSelectField,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<SelectFieldConfig, FieldValue>, any>'.
})<SelectFieldConfig, FieldValue>(SelectField);
