import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants';
import type { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field';

type Validator<T = unknown> = NonNullable<
	Parameters<typeof withFormField<T, undefined>>[0]['validator']
>;

export const validateRequired: Validator<unknown | unknown[]> = (
	value: unknown | unknown[] | undefined,
	isRequired: boolean,
	_ = undefined,
) => {
	if (isRequired) {
		if (!value || (Array.isArray(value) && !value.length)) {
			return REQUIRED;
		}
	}
	return undefined;
};
