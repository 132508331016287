// file to be removed with clean up of `jwm-form-field-configs` feature gate
import {
	ASSIGNEE_TYPE,
	NUMBER_CF_TYPE,
	URL_CF_TYPE,
	TEXT_CF_TYPE,
	DESCRIPTION_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	DUE_DATE_TYPE,
	SUMMARY_TYPE,
	LABELS_TYPE,
	LABELS_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	USER_CF_TYPE,
	MULTI_USER_CF_TYPE,
	CASCADING_SELECT_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	TEAM_CF_TYPE,
	PRIORITY_TYPE,
	TEXT_AREA_CF_TYPE,
	ATTACHMENT_TYPE,
	EPIC_TYPE,
	EPIC_COLOR_TYPE,
	EPIC_NAME_TYPE,
	FIX_VERSIONS_TYPE,
	AFFECTS_VERSIONS_TYPE,
	MULTI_VERSION_CF_TYPE,
	COMPONENTS_TYPE,
	SPRINT_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import { isWorkIntakeRollout1EnabledNoExp } from '../work-intake-rollout-1-experiment';

/**
 * Utility type
 * This will make a map that requires all keys from union type to be declared in the object
 * e.g.
 * type A = 'key1' | 'key2'
 *
 * // both key1 and key2 are mandatory keys, and will throw error if it's missing
 * const example: EnumMap<A, string> = {
 *   key1: 'example value1',
 *   key2: 'example value2',
 * }
 *
 * Copied from https://github.com/facebook/flow/issues/5276#issuecomment-346975015
 */
// @ts-expect-error - TS2536 - Type 'K' cannot be used to index type 'O'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
type EnumMap<K extends string, V, O extends any = any> = O & Record<K, V & O[K]>;

type FormSupportedVisibleFieldType =
	| typeof NUMBER_CF_TYPE
	| typeof URL_CF_TYPE
	| typeof TEXT_CF_TYPE
	| typeof DATE_CF_TYPE
	| typeof DATETIME_CF_TYPE
	| typeof DUE_DATE_TYPE
	| typeof SUMMARY_TYPE
	| typeof DESCRIPTION_TYPE
	| typeof LABELS_TYPE
	| typeof LABELS_CF_TYPE
	| typeof SELECT_CF_TYPE
	| typeof MULTI_SELECT_CF_TYPE
	| typeof MULTI_CHECKBOXES_CF_TYPE
	| typeof RADIO_BUTTONS_CF_TYPE
	| typeof USER_CF_TYPE
	| typeof MULTI_USER_CF_TYPE
	| typeof CASCADING_SELECT_CF_TYPE
	| typeof GROUP_CF_TYPE
	| typeof MULTI_GROUP_CF_TYPE
	| typeof TEAM_CF_TYPE
	| typeof PRIORITY_TYPE
	| typeof TEXT_AREA_CF_TYPE
	| typeof ATTACHMENT_TYPE
	| typeof ASSIGNEE_TYPE
	| typeof TEAMS_PLATFORM_CF_TYPE;

/**
 * Map to enforce that all the supported field type in form has the corresponding value
 */
export type FormSupportedVisibleFieldTypeMap<ValueType> = EnumMap<
	FormSupportedVisibleFieldType,
	ValueType
>;

/**
 * An object used for look up whether a certain field type is supported (only for fields that should be shown in form)
 * Created as an object instead of map so we can reuse the `FormSupportedFieldTypeMap` type
 * it will ensure we have single source of truth for all supported fields (using type checking)
 */
const supportedVisibleFieldsMap: FormSupportedVisibleFieldTypeMap<boolean> = {
	[NUMBER_CF_TYPE]: true,
	[URL_CF_TYPE]: true,
	[TEXT_CF_TYPE]: true,
	[DATE_CF_TYPE]: true,
	[DATETIME_CF_TYPE]: true,
	[DUE_DATE_TYPE]: true,
	[SUMMARY_TYPE]: true,
	[DESCRIPTION_TYPE]: true,
	[LABELS_TYPE]: true,
	[LABELS_CF_TYPE]: true,
	[SELECT_CF_TYPE]: true,
	[MULTI_SELECT_CF_TYPE]: true,
	[MULTI_CHECKBOXES_CF_TYPE]: true,
	[RADIO_BUTTONS_CF_TYPE]: true,
	[USER_CF_TYPE]: true,
	[MULTI_USER_CF_TYPE]: true,
	[CASCADING_SELECT_CF_TYPE]: true,
	[GROUP_CF_TYPE]: true,
	[MULTI_GROUP_CF_TYPE]: true,
	[TEAM_CF_TYPE]: true,
	[PRIORITY_TYPE]: true,
	[TEXT_AREA_CF_TYPE]: true,
	[ATTACHMENT_TYPE]: true,
	[ASSIGNEE_TYPE]: true,
	[TEAMS_PLATFORM_CF_TYPE]: true,
};

export const isFieldTypeSupported = (fieldType: string) => fieldType in supportedVisibleFieldsMap;

// functions below to be migrated to utils/field-config/index.tsx
// with clean up of `jwm-form-field-configs` feature gate

/**
 * List of fields IDs that do not make sense to be shown in form
 * This is based on https://hello.atlassian.net/wiki/spaces/JBus/pages/988271414/Forms+-+Field+support+prioritisation Quadrant 4
 */
const improperFieldIdsForForm = new Set([
	'created',
	'issuelinks',
	'issuetype',
	'parent',
	'project',
	'resolution',
	'security',
	'subtasks',
	'timeoriginalestimate',
	'updated',
	'updated',
	'votes',
	'watches',
]);

// List of field IDs that are being excluded above in `improperFieldIdsForForm` but are being introduced
// as part of `forms_for_work_intake_rollout_1_experiment`.
//
// To be removed from above list on feature gate cleanup.
const extendedFieldIdsForForm = new Set(['parent']);

const improperFieldTypesForForm = new Set<string>([
	EPIC_TYPE,
	EPIC_COLOR_TYPE,
	EPIC_NAME_TYPE,
	FIX_VERSIONS_TYPE,
	AFFECTS_VERSIONS_TYPE,
	MULTI_VERSION_CF_TYPE,
	COMPONENTS_TYPE,
	SPRINT_TYPE,
]);

// List of field IDs that are being excluded above in `improperFieldTypesForForm` but are being introduced
// as part of `forms_for_work_intake_rollout_1_experiment`.
//
// To be removed from above list on feature gate cleanup.
const extendedFieldTypesForForm = new Set<string>([
	COMPONENTS_TYPE,
	AFFECTS_VERSIONS_TYPE,
	FIX_VERSIONS_TYPE,
	MULTI_VERSION_CF_TYPE,
	SPRINT_TYPE,
]);

export const isFieldImproperForForm = ({
	fieldId,
	fieldName,
	fieldType,
}: {
	fieldId: string;
	fieldName: string;
	fieldType: string;
}) => {
	const isImproperFieldId = improperFieldIdsForForm.has(fieldId);

	if (isWorkIntakeRollout1EnabledNoExp()) {
		if (isImproperFieldId && !extendedFieldIdsForForm.has(fieldId)) {
			return true;
		}
	} else if (isImproperFieldId) {
		return true;
	}

	const isImproperFieldType = improperFieldTypesForForm.has(fieldType);

	if (isWorkIntakeRollout1EnabledNoExp()) {
		if (isImproperFieldType && !extendedFieldTypesForForm.has(fieldType)) {
			return true;
		}
	} else if (isImproperFieldType) {
		return true;
	}

	// Cases for others where fieldId and fieldType are not sufficient
	// and requires combination of both
	// e.g. for some custom field because their fieldId is different in every instance

	// Flagged field
	if (fieldName === 'Flagged' && fieldType === MULTI_CHECKBOXES_CF_TYPE) {
		return true;
	}

	return false;
};
