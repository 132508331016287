import React, { type KeyboardEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import InlineAssigneeEdit from '@atlassian/jira-issue-field-assignee/src/ui/inline/edit-view/main.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
// eslint-disable-next-line jira/styled/no-styled-import-alias
import { FieldContainer as FieldContainerCommon } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import { validateAssigneeField } from '../../common/utils/validators';
import messages from './messages';
import type { Props, UserPickerFieldConfig, FieldValue } from './types';
import { transformDefaultAssigneeValue } from './utils';

export const AssigneeField = (props: Props) => {
	const { formatMessage } = useIntl();
	const {
		autoCompleteUrl = '',
		width = isVisualRefreshEnabled() ? '100%' : props.width,
		fieldProps: { value, onChange, id, ...fieldProps },
		autoFocus = false,
		isReadOnly = false,
	} = props;
	const [{ data: loggedInUser }] = useUserSubscriber();

	const onAssignToMeClick = () => {
		onChange(loggedInUser);
	};

	const onAddAssigneeKeyDown = useCallback(
		(event: KeyboardEvent<HTMLElement>) => {
			if (id && (event.key === 'Enter' || event.key === ' ')) {
				onChange(loggedInUser);
				// this focus was implemented with getElementById as there is problem here using ref in UserPicker
				// doesn't pass ref to input inside the component

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				setTimeout(() => document.getElementById(id)?.focus());
			}
		},
		[id, loggedInUser, onChange],
	);

	const showAssignToMe = loggedInUser && !isReadOnly;
	return (
		<FieldContainer width={width}>
			<InlineAssigneeEdit
				{...fieldProps}
				value={value}
				onChange={onChange}
				shouldPreloadAssignToMe
				autoCompleteUrl={autoCompleteUrl}
				enableAutomaticOption
				autoFocus={autoFocus}
				isDisabled={isReadOnly}
				width={width}
				inputId={id}
				openMenuOnClick
			/>
			{showAssignToMe && (
				<Button
					appearance="link"
					spacing="none"
					onClick={onAssignToMeClick}
					onKeyDown={onAddAssigneeKeyDown}
					testId="issue-create-commons.ui.assignee-field.assing-to-me-button"
				>
					{formatMessage(messages.assignToMe)}
				</Button>
			)}
		</FieldContainer>
	);
};

export default withFormField({
	validator: validateAssigneeField,
	transformDefaultValue: transformDefaultAssigneeValue,
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<UserPickerFieldConfig, UserValue>, any>'.
})<UserPickerFieldConfig, FieldValue>(AssigneeField);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainerStylesWithVisualRefreshFFOff = styled(FieldContainerCommon)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button': {
		marginTop: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainerStylesWithVisualRefreshFFOn = styled(FieldContainerCommon)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button': {
		marginTop: token('space.050', '4px'),
		position: 'absolute',
		right: '0px',
		top: '-1px',
	},
});

const FieldContainer = componentWithCondition(
	() => isVisualRefreshEnabled(),
	FieldContainerStylesWithVisualRefreshFFOn,
	FieldContainerStylesWithVisualRefreshFFOff,
);
