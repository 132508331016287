import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

/**
 * Provides an error message to use in the `options` prop of an ak-select when the fetch has failed
 * For this to work correctly:
 *  - the Select component must have the prop `filterOption: filterOptionByLabelAndFilterValues`, the utility from this same package
 */
export const getFailedFetchSelectOptions = (
	/**
	 * Need the user's query string so that the error message always shows despite what the user types into the select component
	 */
	query: string,
	/**
	 * The error label to render to communicate that the fetch has failed
	 */
	label: ReactNode,
) => [
	{
		options: [
			{
				label,
				value: '',
				isDisabled: true,
				filterValues: [query], // filterOptionByLabelAndFilterValues will check `filterValues` and since it contains the user's query, this option will always show
			},
		],
	},
];

const DefaultFailedFetchMessage = () => {
	const { formatMessage } = useIntl();
	const failedFetchMessage = messages.failedFetch;
	return (
		<FailedFetchOptionWrapper>
			<span>
				{formatMessage(failedFetchMessage)} {/* aks my rules */}
				{/* eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage */}
				<a role="button" href={window.location.href} onClick={() => window.location.reload()}>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.reloadIssueIssueTermRefresh
							: messages.reloadIssue,
					)}
				</a>
			</span>
		</FailedFetchOptionWrapper>
	);
};

/**
 * A version of {@link getFailedFetchSelectOptions} that contains a default styled error message
 */
export const getFailedFetchSelectOptionsWithDefaultLabel = (query: string) =>
	getFailedFetchSelectOptions(query, <DefaultFailedFetchMessage />);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FailedFetchOptionWrapper = styled(OptionWrapper)({
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ImgSpan = styled.span({
	display: 'flex',
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FlexSpan = styled.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorMessageWrapper = styled.div<{
	errorMessagePosition: undefined | 'top' | 'bottom';
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(
		props: any, // eslint-disable-line @typescript-eslint/no-explicit-any
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	) => (props.errorMessagePopsition === 'top' ? { 'margin-bottom': `${gridSize * 0.75}px` } : {}),
);
