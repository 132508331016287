import { useMemo } from 'react';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';

export const isWorkIntakeRollout1EnabledNoExp = () => {
	const [config] = UNSAFE_noExposureExp('forms_for_work_intake_rollout_1_experiment');
	return config.get('isEnabled', false);
};

export const useWorkIntakeRollout1AnalyticsAttrs = () =>
	useMemo(
		() => ({
			isWorkIntakeRollout1Enabled: isWorkIntakeRollout1EnabledNoExp(),
		}),
		[],
	);
