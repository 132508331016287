import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';
import UserPicker, { type ActionTypes, type Value } from '@atlaskit/user-picker';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useInvitePeopleDrawer } from '@atlassian/jira-invite-people-drawer/src/controllers';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { INVITE_PEOPLE_ID, UNASSIGNED_ID } from '../../../common/constants';
import type { UserValue } from '../../../common/types';
import { getUserFromUserOption } from '../../../common/utils';
import messages from '../../../messages';
import { useAssigneeOptions } from '../../../services/assignee-options';
import useUsersQuery from '../../../services/users-query';
import type { InlineAssigneePickerEditProps } from './types';

const DEFAULT_SEARCH_DEBOUCE_TIMEOUT = 300;

const InlineAssigneeFieldOptionsLoadExperience = new UFOExperience(
	'inline-assignee-picker.field-options-load',
	{
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
		category: 'inline-assignee-picker.field-options-load',
	},
);

const InlineAssigneePickerEdit = ({
	shouldPreloadAssignToMe = false,
	autoCompleteUrl,
	value = null,
	width,
	isDisabled,
	onFocus,
	onBlur,
	onChange,
	searchDebounceTimeout = DEFAULT_SEARCH_DEBOUCE_TIMEOUT,
	enablePeopleInvite = true,
	enableAutomaticOption = false,
	isSubtle = false,
	appearance = 'normal',
	autoFocus = false,
	inputId,
	openMenuOnClick = false,
	ariaLabel,
	...fieldProps
}: InlineAssigneePickerEditProps) => {
	const intl = useIntl();
	const self = useRef<{
		previousQuery?: string;
	}>({ previousQuery: undefined }).current;
	const [{ data: users, loading, error }, fetchUsers] = useUsersQuery(autoCompleteUrl || '');
	const [assigneeValue, assigneeOptions] = useAssigneeOptions(
		value,
		users,
		intl,
		shouldPreloadAssignToMe,
		self.previousQuery,
		enablePeopleInvite,
		enableAutomaticOption,
	);
	const [, { openDrawer }] = useInvitePeopleDrawer();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (loading) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: 'inlineAssigneePicker',
					action: 'loading',
				}),
				'inlineAssigneeOptions loading',
			);
			InlineAssigneeFieldOptionsLoadExperience.start();
		}
		if (error) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: 'inlineAssigneePicker',
					action: 'failed',
				}),
				'inlineAssigneeOptions failed',
			);
			InlineAssigneeFieldOptionsLoadExperience.failure();
		}
		if (users.length) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: 'inlineAssigneePicker',
					action: 'loaded',
				}),
				'inlineAssigneeOptions loaded',
			);
			InlineAssigneeFieldOptionsLoadExperience.success();
		}
	}, [loading, error, users, createAnalyticsEvent]);

	const handleSearch: (query?: string) => void = useMemo(
		() =>
			debounce((query?: string) => {
				/*
				 * Added extra condition to skip search. it's getting called during onBlur because previousQuery is undefined and query is empty string
				 * then query !== self.previousQuery will be true and fetchUsers getting called unnecessarily
				 */
				if (query === '' && self.previousQuery === undefined) {
					return;
				}
				query !== self.previousQuery && fetchUsers(query);
				self.previousQuery = query;
			}, searchDebounceTimeout),
		// go/jfe-eslint
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fetchUsers, searchDebounceTimeout, self.previousQuery],
	);

	const handleOnFocus = useCallback(
		(sessionId?: string) => {
			onFocus?.(sessionId);

			if (!users || !users.length) {
				fetchUsers();
			}
		},
		[onFocus, users, fetchUsers],
	);

	const handleOnBlur = useCallback(
		(sessionId?: string) => {
			onBlur?.(sessionId);
			self.previousQuery = undefined;
		},
		[onBlur, self],
	);

	const handleOnChange = useCallback(
		(updatedValue: Value, action: ActionTypes) => {
			if (!updatedValue || Array.isArray(updatedValue)) {
				return;
			}

			if (
				enablePeopleInvite &&
				updatedValue.id === INVITE_PEOPLE_ID &&
				expValEquals('invite.from.assign', 'cohort', 'variation')
			) {
				openDrawer(createAnalyticsEvent ?? null, { inviteFlow: 'assignee' });
				onChange?.(value, action);
				return;
			}

			let newValue: UserValue;

			if (updatedValue.id === UNASSIGNED_ID || updatedValue.id === INVITE_PEOPLE_ID) {
				newValue = null;
			} else {
				newValue = getUserFromUserOption(updatedValue);
			}

			onChange?.(newValue, action);
		},
		[enablePeopleInvite, onChange, openDrawer, value, createAnalyticsEvent],
	);

	/*
	 * user picker default opens on focus, fetchUsers has to be called onFocus
	 * when openMenuOnClick is enabled then fetchUsers should be called during onOpen rather than onFocus
	 */
	const controlProps = openMenuOnClick
		? { openMenuOnClick, onOpen: handleOnFocus }
		: { onFocus: handleOnFocus };

	return (
		<UserPicker
			inputId={inputId}
			fieldId={ASSIGNEE_TYPE}
			autoFocus={autoFocus}
			placeholder={intl.formatMessage(messages.searchForAssignee)}
			isLoading={loading}
			options={assigneeOptions}
			value={assigneeValue}
			isMulti={false}
			isClearable={false}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			onInputChange={handleSearch}
			subtle={isSubtle}
			appearance={appearance}
			width={width}
			isDisabled={isDisabled}
			ariaLabelledBy={fieldProps['aria-labelledby']}
			UNSAFE_hasDraggableParentComponent
			ariaLabel={ariaLabel}
			{...controlProps}
		/>
	);
};

export default InlineAssigneePickerEdit;
