import React, { useRef } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { FieldContainer } from '@atlassian/jira-issue-create-commons/src/common/ui/fields/styled.tsx';
import { withFormField } from '@atlassian/jira-issue-create-commons/src/common/ui/with-form-field/index.tsx';
import { validateDescriptionField } from '@atlassian/jira-issue-create/src/common/ui/fields/description-field/utils';
import { isWorkIntakeRollout1EnabledNoExp } from '../../../utils/work-intake-rollout-1-experiment';
import { DescriptionEdit } from '../../description-edit';
import type { Props, DescriptionFieldConfig, FieldValue } from './types';

export const DescriptionField = (props: Props) => {
	const editorKey = useRef(Date.now());
	const {
		fieldProps: { value, onChange },
		mediaUploadContext,
	} = props;

	return (
		<FieldContainer width="100%">
			<DescriptionEdit
				key={editorKey.current}
				onChange={onChange}
				defaultValue={value}
				mediaContext={{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore - TS2740 - Type '{}' is missing the following properties from type 'ViewContext': clientId, serviceHost, token, tokenLifespanInMs, and 3 more
					viewContext: mediaUploadContext || {},
					uploadContext: mediaUploadContext,
					userAuth: null,
				}}
			/>
		</FieldContainer>
	);
};

/**
 * TODO: inline all of the below on cleanup of
 * isWorkIntakeRollout1EnabledNoExp()
 */
type DescriptionValue = ADF | undefined;

type DescriptionValidator = Parameters<
	typeof withFormField<DescriptionValue, undefined>
>[0]['validator'];

const validator: DescriptionValidator = (value, isRequired) =>
	isWorkIntakeRollout1EnabledNoExp() ? validateDescriptionField(value, isRequired) : undefined;

export default withFormField<DescriptionValue, undefined>({
	validator,
})<DescriptionFieldConfig, FieldValue>(DescriptionField);
