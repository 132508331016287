import { fg } from '@atlassian/jira-feature-gating';
import {
	isFieldTypeSupported as isFieldTypeSupportedNew,
	isProjectScopedFieldType,
} from '../../common/utils/field-config';
import { isFieldTypeSupported } from '../../common/utils/supported-fields';
import { isWorkIntakeRollout1EnabledNoExp } from '../../common/utils/work-intake-rollout-1-experiment';
import type { FieldsMeta } from '../../services/field-meta/types';
import type { JiraBusinessFormWithFieldData } from '../../services/get-form/types';
/**
 * Form is valid for submission only when the form contains all required fields
 * And all fields are supported
 */

export const getFormValidationData = (
	formData?: JiraBusinessFormWithFieldData,
	fieldMetaData?: FieldsMeta,
) => {
	if (!formData || !fieldMetaData) {
		return null;
	}

	// required fields in form that are also required in the issue type
	const formRequiredFields = formData.fields.filter(
		(f) => f.isRequired && !!fieldMetaData[f.fieldId],
	);

	// required fields in project
	const requiredFieldIds = Object.keys(fieldMetaData).filter(
		(fieldId) => fieldMetaData[fieldId].required,
	);

	const formContainsAllRequiredFields = formRequiredFields.length === requiredFieldIds.length;

	const isAllRequiredFieldsSupported = requiredFieldIds.every((f) => {
		const fieldType = fieldMetaData[f].schema?.custom || fieldMetaData[f].schema?.system;
		if (!fieldType) {
			return false;
		}

		return fg('jwm-form-field-configs')
			? isFieldTypeSupportedNew(fieldType)
			: isFieldTypeSupported(fieldType);
	});

	const fieldsRequiredByForm = formData.fields.filter(
		(f) => f.isRequiredByForm && !!fieldMetaData[f.fieldId],
	);

	const hasProjectScopedRequiredFields = fieldsRequiredByForm.some(({ type: fieldType }) => {
		if (!isWorkIntakeRollout1EnabledNoExp() || !fieldType) {
			return false;
		}

		return isProjectScopedFieldType(fieldType);
	});

	return {
		isFormSubmissionValid: formContainsAllRequiredFields && isAllRequiredFieldsSupported,
		hasMissingSupportedRequiredFields:
			!formContainsAllRequiredFields && isAllRequiredFieldsSupported,
		hasUnsupportedRequiredFields: !isAllRequiredFieldsSupported,
		hasProjectScopedRequiredFields,
	};
};
